.Box {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 10vh;
}
.Button {
    display: flex;
    justify-content: center;
}
