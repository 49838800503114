.about-container {
    display: block;
    justify-content: center;
    height: auto;
    background-color: #ff914d;
}

.header-line {
    width: 100%;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Playfair Display', sans-serif;
    font-size: 54px;
    color: black;
    font-weight: bold;

}

.about-background {
    position: absolute;
    width: 100%;
    height: 60vh;
    opacity: 0.1;
}

.about-title {
    text-align: center;
}

.story {
    width: 100%;
    height: 60vh;
    display: block;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.story-title {
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.story-text {
    height: 70%;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: flex-start;
}

.we-do {
    background-color: whitesmoke;
    height: 60vh;
    display: block;
}

.we-do-title {
    text-align: center;
    width: 100%;
}

.we-do-cards {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100%;
}

.testimony {
    background-color: aquamarine;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.our-team {
    background-color: red;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icons {
    display: flex;
    justify-content: space-around;
    width: 100%;
}
