.box {
    margin-top: 10px;
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    border: outset;
    border-radius: 10px;
    box-shadow: 5px 5px lightgray;
    cursor: pointer;
}

.buttonInvest {
    width: 100px;
}

.centerButton {
    margin-bottom: 5px;
    margin-left: 20%;
}


.container-cardProperty {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
        "up-left up-right"
        "down-left down-right";
    grid-gap: 0.2rem;
    /* background-color: rgb(70, 72, 72); */
}

.up-left {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 1px 1px 1px lightgray;
}

.down-left {
    background-color: white;
    display: flex;
    justify-content: center;
    box-shadow: 1px 1px 1px 1px lightgray;
}

.up-right {
    background-color: white;
    display: flex;
    justify-content: center;
    box-shadow: 1px 1px 1px 1px lightgray;
}

.down-right {
    background-color: white;
    display: flex;
    justify-content: center;
    box-shadow: 1px 1px 1px 1px lightgray;
}

.favorite-added {
    color: red;
}
