.container {
    display: grid;
    height: 85vh;
    width: 85vw;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 0.5fr 1.5fr 1.2fr .5fr;
    grid-template-areas:
        "sidebar main main main main"
        "sidebar main main main main"
        "sidebar main main main main"
        "sidebar main main main main";
    grid-gap: 0.2rem;
}
main{
    grid-area: main;
    /* overflow: hidden; */
    display: flex;
    justify-content: center;
    width: auto;
    height: auto;
    margin-left: 20px;
}
.sidebar{
    border-width: 1cm;
    border-color: black;
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
    grid-area: sidebar;
    justify-content: center;
    align-items: flex-start;
}

footer{
    grid-area: footer;
}

.searchBar{
    background-color: gainsboro;
    width: 70%;
    height: 30px;
    display: flex;
    justify-content: flex-end;
    border-radius: 35px;
    margin-left: 20px;
}

input {
    background-color: rgb(238, 188, 113);
    border: none;
    outline: none;
}

input:focus {
    background-color: #fae292;
    border: none;
    outline: none;
}
.mapImage {
    width: 100%;
    height: 100%;
}

input {
    width: 85%;
}
