.homeTitle{
    color: #ff914d;
    font-size: 7em;
    text-align: center;
    padding-top: 5%;
}

.valueProposition {
    color: #ffbd59;
    font-size: 3em;
    text-align: center;
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 5%;
    padding-bottom: 5%;
}
